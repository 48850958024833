import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Form,
  Input,
  Button,
  Tooltip,
  DatePicker,
  Radio,
  Checkbox,
  Spin,
  Select,
  Alert,
} from "antd";
import { Formik } from "formik";
import {
  uploadBusinessKoinVoice,
  putPersonalInfoKoinvoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  putPersonalInfoProfileKoinvoice,
  actionButtonBackPersonalInfo,
  businessInformationKoinvoice,
  actionPutAppGuarantorCreate,
  actionGetPersonalInfoLocal,
} from "../../../stores/actions/koinvoiceLoan";
import UploadKtpModal from "../../ModalUpload/UploadKtpModal";
import UploadSelfieKtpModal from "../../ModalUpload/UploadSelfieKtpModal";
import { CheckCircleTwoTone } from "@ant-design/icons";
import ModalViewKtp from "../../ModalView";
import ModalViewMatchKtp from "../../ModalView";
import ModalViewKK from "../../ModalView";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import { ArrowLeftOutlined } from "@ant-design/icons";
import cookie from "react-cookies";
import UploadKkModal from "../../ModalUpload/UploadKkModal";
import _ from "lodash";
import koinvoiceServices from "../../../services/koinvoice";
import { trimOssUrl } from "../../../library/fileHelper";

const { Option } = Select;

const isMobile = () => {
  const width = window.innerWidth;
  if (width > 700) return true;
  return false;
};

function Personalnfo({
  init: { language },
  koinvoice: {
    upload,
    uploadKtpSucess,
    uploadKtpError,
    uploadSelfie,
    uploadMatchKtpSucess,
    uploadMatchKtpError,
    uploadKK,
    isShowPutBusinessInfoProfile,
    putPersonalInfoProfileError,
    district,
    isShowLoading,
    dataPersonalInformationLocal,
  },
  businessInformationKoinvoice,
  businessInformation,
  auth,
  actionGetPersonalInfoLocal,
  actionButtonBackPersonalInfo,
  uploadBusinessKoinVoice,
  putPersonalInfoProfileKoinvoice,
  getDistrictKoinvoice,
  getSubDistrictKoinvoice,
  nextPage,
  prevPage,
  business_type,
}) {
  const businessType =
    business_type === "home_business" ||
    business_type === "individual" ||
    business_type === "industri_rumah_tangga";
  const [visibleUploadKtp, setVisibleUploadKtp] = useState(false);
  const [visibleUploadSelfieKtp, setVisibleUploadSelfieKtp] = useState(false);
  const [visibleUploadKk, setVisibleUploadKk] = useState(false);
  const [isShowViewKtpModal, setIsShowViewKtpModal] = useState(false);
  const [isShowViewMatchKtpModal, setIsShowViewMatchKtpModal] = useState(false);
  const [isShowViewKKModal, setIsShowViewKKModal] = useState(false);
  const [isShowCurrentlyAddress, setIsShowCurrentlyAddress] = useState(true);
  const [validationImageKtp, setValidationImageKtp] = useState(parseInt(0));
  const [validationImageKtpPersonal, setValidationImageKtpPersonal] = useState(
    parseInt(0),
  );
  const [
    isShowvalidationImageKtpPersonal,
    setIsShowValidationImageKtpPersonal,
  ] = useState(false);
  const [validationMatchImageKtp, setValidationMatchImageKtp] = useState(0);
  const [validationMatchImageKtpFailed, setValidationMatchImageKtpFailed] =
    useState(parseInt(0));
  const [
    isShowvalidationMatchImageKtpFailed,
    setIsShowValidationMatchImageKtpFailed,
  ] = useState(false);
  const [validationImageKk, setValidationImageKk] = useState(parseInt(0));
  const [isShowLoadingSubmitPeronalInfo, setIsShowLoadingSubmitPersonalInfo] =
    useState(false);
  const { loan_application_id } =
    cookie.load("purpose") !== undefined && cookie.load("purpose").data;
  const [homeAddress, setHomeAddress] = useState("different-address");
  const [pathKtp, setPathKtp] = useState("");
  const [pathSelfieKtp, setSelfieKtp] = useState("");
  const [pathKK, setPathKK] = useState("");
  const [isShowHideAlertKtp, setIsShowAlertKtp] = useState("");
  const [isShowHideAlertUploadKtp, setIsShowAlertUploadKtp] = useState("");
  const [isShowHideAlertMatchKtp, setIsShowAlertMatchKtp] = useState("");
  const [isCountryDisabled, setIsCountryDisabled] = useState(false);
  // const [code, setCode] = useState();
  const [isShowHideAlertUploadMatchKtp, setIsShowAlertUploadMatchKtp] =
    useState("");
  const [checkBusinessTypeInformation, setCheckBusinessTypeInformation] =
    useState(false);
  const [subDistrict, setSubDistrict] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const mounted = useRef();
  const schemaPersonalInfo = yup.object().shape({
    ktp_image: yup.string().required(language.loanShareHolder.requiredKtp),
    ktp_selfie_image: yup
      .string()
      .required(language.loanShareHolder.requiredKtpSelfie),
    nik: yup
      .string()
      .required(language.loanShareHolder.requiredNik)
      .length(16, language.loanShareHolder.requiredNikLength),
    owner_name: yup
      .string()
      .required(language.loanShareHolder.requiredOwnerName),
    owner_birthdate: yup
      .string()
      .required(language.loanShareHolder.requiredDob),
    owner_place_of_birth: yup
      .string()
      .required(language.loanShareHolder.requiredPob),
    owner_gender: yup
      .string()
      .required(language.loanShareHolder.requiredGender),
    ktp_occupation: yup
      .string()
      .required(language.loanShareHolder.requiredKtpOccupation),
    citizenship: yup
      .string()
      .required(language.loanShareHolder.requiredCitizenship),
    country: yup.string().required(language.loanShareHolder.requiredCountry),
    owner_marital_status: yup
      .string()
      .required(language.loanShareHolder.requiredMaritalStatus),
    kk_image: checkBusinessTypeInformation
      ? yup.string().required(language.loanShareHolder.requiredKkHomeBusiness)
      : yup.string(),
    owner_address: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredAddress)
      : yup.string(),
    owner_district: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredDistrict)
      : yup.string(),
    owner_sub_district: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredSubDistrict)
      : yup.string(),
    owner_province: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredProvince)
      : yup.string(),
    owner_city: isShowCurrentlyAddress
      ? yup.string().required(language.loanShareHolder.requiredCity)
      : yup.string(),
    owner_address_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredAddress),
    owner_district_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredDistrict),
    owner_sub_district_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredSubDistrict),
    owner_province_duplicate: isShowCurrentlyAddress
      ? ""
      : yup.string().required(language.loanShareHolder.requiredProvince),
    owner_phone: yup
      .string()
      .required(language.loanShareHolder.requiredMobileNumber)
      .min(6, language.loanShareHolder.requiredMobileNumberLength),
    owner_email: yup
      .string()
      .email(language.loanShareHolder.wrongEmail)
      .required(language.loanShareHolder.requiredEmail),
    postal_code: yup
      .string()
      .required(language.loanShareHolder.requiredPostalCode)
      .length(5, language.loanShareHolder.requiredPostalCodeLength),
    postal_code_duplicate: isShowCurrentlyAddress
      ? yup.string()
      : yup
          .string()
          .required(language.loanShareHolder.requiredPostalCode)
          .length(5, language.loanShareHolder.requiredPostalCodeLength),
    current_occupation: !isShowCurrentlyAddress
      ? yup
          .string()
          .required(language.loanShareHolder.requiredCurrentOccupation)
      : yup.string(),
    has_lived_for: yup.string(),
    home_ownership_status: yup
      .string()
      .required(language.loanShareHolder.requiredHomeOwnershipStatus),
    education_level: yup
      .string()
      .required(language.loanShareHolder.requiredEducation),
    mother_maiden_name: yup
      .string()
      .required(language.loanShareHolder.requiredMotherMaidenName),
    monthly_income_range: businessType
      ? yup
          .string()
          .required(language.loanShareHolder.requiredMonthlyIncomeRange)
      : "",
    emergency_contact_name: yup
      .string()
      .required(language.loanShareHolder.requiredEmergencyContactName),
    emergency_contact_phone_number: yup
      .string()
      .required(language.loanShareHolder.requiredEmergencyContactPhoneNumber)
      .matches(
        /^(62)+/,
        language.loanShareHolder.requiredEmergencyContactPhoneNumberPhoneArea,
      )
      .matches(
        /^\d{7,15}$/,
        language.loanShareHolder.requiredEmergencyContactPhoneNumberLength,
      ),
  });
  const paramsSubmit =
    JSON.parse(localStorage.getItem("paramsSubmit")) !== null &&
    JSON.parse(localStorage.getItem("paramsSubmit"));
  const ownerInfoDate =
    JSON.parse(localStorage.getItem("paramsSubmitDate")) !== null &&
    JSON.parse(localStorage.getItem("paramsSubmitDate"));

  useEffect(() => {
    window.scrollTo(0, 0);
    businessInformationKoinvoice(); // eslint-disable-next-line
  }, []);

  function actionChangeKtpUpload(values, setFieldValue, currentOccupation) {
    setPathKtp(values.path);
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      let params = {
        base64: [fileReader.result],
        email:
          auth.email || (businessInformation && businessInformation.data.email),
      };
      uploadBusinessKoinVoice(
        params,
        handleChangeShowUploadKtp,
        setFieldValue,
        setValidationImageKtp,
        validationImageKtp,
        setValidationImageKtpPersonal,
        validationImageKtpPersonal,
        setIsShowAlertKtp,
        setIsShowAlertUploadKtp,
        currentOccupation,
      );
    };
    fileReader.readAsDataURL(values);
  }

  function handleChangeShowUploadKtp(value) {
    setVisibleUploadKtp(!visibleUploadKtp);
    if (value === "reUploadKtp") {
      setIsShowValidationImageKtpPersonal(true);
      setValidationImageKtpPersonal(0);
    }
  }

  function handleChangeShowUploadKk(value) {
    setVisibleUploadKk(!visibleUploadKk);
  }

  function handleChangeShowUploadSelfieKtp(value) {
    setVisibleUploadSelfieKtp(!visibleUploadSelfieKtp);
    if (value === "reUploadKtp") {
      setIsShowValidationMatchImageKtpFailed(true);
      setValidationMatchImageKtpFailed(0);
    }
  }

  function handleChangeShowViewKtp() {
    setIsShowViewKtpModal(!isShowViewKtpModal);
  }

  function handleChangeShowViewMatchKtp() {
    setIsShowViewMatchKtpModal(!isShowViewMatchKtpModal);
  }

  function handleChangeShowViewKK() {
    setIsShowViewKKModal(!isShowViewKKModal);
  }

  function onChangeAddressCurrentlyLive(e) {
    setIsShowCurrentlyAddress(e.target.checked);
  }

  function onChangeCurrentHomeAddress(e) {
    setHomeAddress(e.target.value);
    if (e.target.value === "same-with-business-address") {
      setIsShowCurrentlyAddress(true);
    }
  }

  function handleChangePhoneInput(value, option, setFieldValue) {
    setFieldValue("owner_phone", value);
  }

  function handleChangeEmergencyContactPhoneNumber(
    value,
    option,
    setFieldValue,
  ) {
    setFieldValue("emergency_contact_phone_number", value);
  }

  function handleChangeEmergencyContactRelationship(
    value,
    option,
    setFieldValue,
  ) {
    setFieldValue("emergency_contact_relationship", option?.value);
  }

  function actionChangeSearchDistrict(value) {
    getDistrictKoinvoice(value);
  }

  function actionChangeDistrict(value, option, setFieldValue) {
    //const districtValidation =
    // district.data && district.data.find((dis) => dis.name === value);
    //setCode(districtValidation?.code);
    setFieldValue("owner_district", value);
  }

  function actionChangeDistrictDuplicate(value, option, setFieldValue) {
    //const districtValidation =
    // district.data && district.data.find((dis) => dis.name === value);
    //setCode(districtValidation?.code);
    setFieldValue("owner_district_duplicate", value);
  }

  function actionChangeSearchSubDistrict(value) {
    //getSubDistrictKoinvoice(`${value}&district_code=${code}`);
    koinvoiceServices
      .actionGetLocationThirdParty({ params: value })
      .then((res) => {
        if (res?.status === 200 && res?.data !== null) {
          setSubDistrict(res.data);
        }
      });
  }

  function actionChangeSubDistrict(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value,
    );
    setFieldValue("owner_district", districtSubValidation.district);
    setFieldValue("owner_sub_district", districtSubValidation.sub_district);
    setFieldValue("owner_province", districtSubValidation.province);
    setFieldValue("owner_city", districtSubValidation.city);
    setFieldValue("postal_code", districtSubValidation.postal_code);
  }

  function actionChangeSubDistrictDuplicate(value, option, setFieldValue) {
    const districtSubValidation = subDistrict?.find(
      (dis) => dis.name === value,
    );
    setFieldValue("owner_district_duplicate", districtSubValidation.district);
    setFieldValue("owner_province_duplicate", districtSubValidation.province);
    setFieldValue(
      "owner_sub_district_duplicate",
      districtSubValidation.sub_district,
    );
    setFieldValue("postal_code_duplicate", districtSubValidation.postal_code);
  }

  function actionChangeBirthDate(value, option, setFieldValue) {
    setFieldValue("owner_birthdate", option);
  }

  function handleChangeAddressDuplicate(value, option, setFieldValue) {
    setFieldValue("owner_address_duplicate", value.target.value);
  }

  function handleChangeAddress(value, option, setFieldValue) {
    setFieldValue("owner_address", value.target.value);
  }

  function handleChangeNik(value, option, setFieldValue) {
    setFieldValue("nik", value.target.value.toString().replace(/-/g, ""));
  }

  function handleChangeKtpOccupation(value, option, setFieldValue, current) {
    setFieldValue("ktp_occupation", option.value);
    if (!current) {
      setFieldValue("current_occupation", option.value);
    }
  }

  function handleChangeCurrentOccupation(value, option, setFieldValue) {
    setFieldValue("current_occupation", option.value);
  }

  function actionChangeLivedFor(value, option, setFieldValue) {
    setFieldValue("has_lived_for", value);
  }

  function handleChangeOwnerShip(value, option, setFieldValue) {
    setFieldValue("home_ownership_status", value);
  }

  function actionChangeMaritalStatus(value, option, setFieldValue, check) {
    setFieldValue("owner_marital_status", option.value);
    if (option.value === "KAWIN") {
      setCheckBusinessTypeInformation(true);
    } else {
      setCheckBusinessTypeInformation(false);
    }
  }

  function actionChangeCitizenship(value, option, setFieldValue, values) {
    setFieldValue("citizenship", option.value);
    if (option.value === "WNI") {
      setFieldValue("country", "Indonesia");
      setIsCountryDisabled(true);
    } else {
      setIsCountryDisabled(false);
    }
  }

  function actionChangeEducation_level(value, option, setFieldValue, values) {
    setFieldValue("education_level", option.value);
  }

  function actionChangeCountry(value, option, setFieldValue) {
    setFieldValue("country", option.value);
  }

  function actionChangeSearchCountry(value) {
    koinvoiceServices.getCountryKoininvoice({ params: value }).then((res) => {
      if (res?.status === 200 && res?.data !== null) {
        setCountryOption(res.data);
      }
    });
  }

  function handleChangeMonthlyIncomeRange(value, option, setFieldValue) {
    setFieldValue("monthly_income_range", option?.value);
  }

  // function handleChangeRelationship(value, option, setFieldValue) {
  //   setFieldValue("sibling_relation", value);
  // }

  // function handleNpwpInputChange(value, option, setFieldValue) {
  //   setFieldValue(
  //     "npwp",
  //     value.target.value.toString().replace(/[.*+?^_${}()|[\]\\-]/g, "")
  //   );
  // }

  function handleSubmit(e) {
    let {
      ktp_image,
      ktp_selfie_image,
      nik,
      owner_address,
      owner_address_duplicate,
      owner_birthdate,
      owner_district,
      owner_province,
      owner_city,
      owner_district_duplicate,
      owner_email,
      owner_gender,
      owner_marital_status,
      owner_name,
      owner_phone,
      kk_image,
      owner_place_of_birth,
      owner_sub_district,
      owner_sub_district_duplicate,
      owner_province_duplicate,
      postal_code,
      postal_code_duplicate,
      has_lived_for,
      home_ownership_status,
      ktp_occupation,
      citizenship,
      country,
      education_level,
      mother_maiden_name,
      current_occupation,
      monthly_income_range,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_phone_number,
    } = e;
    let paramsSubmit = {
      id: loan_application_id,
      ktp_image,
      ktp_selfie_image,
      nik,
      owner_address: isShowCurrentlyAddress
        ? owner_address
        : owner_address_duplicate,
      owner_birthdate: owner_birthdate.split("-").reverse().join("-"),
      owner_district: isShowCurrentlyAddress
        ? owner_district
        : owner_district_duplicate,
      owner_email,
      owner_gender,
      owner_marital_status,
      owner_phone,
      owner_place_of_birth,
      owner_province,
      owner_city,
      owner_province_duplicate,
      kk_image,
      owner_name,
      postal_code,
      home_ownership_status,
      has_lived_for,
      owner_sub_district: isShowCurrentlyAddress
        ? owner_sub_district
        : owner_sub_district_duplicate,
      ktp_occupation,
      citizenship,
      country,
      education_level,
      mother_maiden_name,
      current_occupation: isShowCurrentlyAddress
        ? ktp_occupation
        : current_occupation,
      monthly_income_range,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_phone_number,
    };
    let personalInfoProfile = {
      product: "koin_invoice",
      fullname: {
        value: owner_name,
      },
      email: {
        value: owner_email,
      },
      phone_number: {
        value: owner_phone,
      },
      ktp: {
        value: trimOssUrl(ktp_image),
        ocr_result: uploadKtpSucess ? JSON.stringify(uploadKtpSucess) : null,
        ocr_status: uploadKtpSucess ? "true" : "false",
      },
      selfie_ktp: {
        value: ktp_selfie_image,
        ocr_result: uploadMatchKtpSucess
          ? JSON.stringify(uploadMatchKtpSucess)
          : null,
        ocr_status: uploadMatchKtpSucess ? "true" : "false",
      },
      nik: {
        value: nik,
      },
      place_of_birth: {
        value: owner_place_of_birth,
      },
      date_of_birth: {
        value: owner_birthdate.split("-").reverse().join("-"),
      },
      gender: {
        value: owner_gender,
      },
      marital_status: {
        value: owner_marital_status,
      },
      address: {
        value: isShowCurrentlyAddress ? owner_address : owner_address_duplicate,
      },
      district: {
        value: isShowCurrentlyAddress
          ? owner_district
          : owner_district_duplicate,
      },
      sub_district: {
        value: isShowCurrentlyAddress
          ? owner_sub_district
          : owner_sub_district_duplicate,
      },
      current_occupation: {
        value: isShowCurrentlyAddress ? ktp_occupation : current_occupation,
      },
      ktp_occupation: {
        value: ktp_occupation,
      },
      citizenship: {
        value: citizenship,
      },
      country: {
        value: country,
      },
      education_level: {
        value: education_level,
      },
      mother_maiden_name: {
        value: mother_maiden_name,
      },
      metas: {
        "personal.user_information.postal_code": {
          value: isShowCurrentlyAddress
            ? postal_code.toString()
            : postal_code_duplicate.toString(),
        },
        "personal.user_information.fullname": {
          value: owner_name,
        },
        "personal.user_location.province": {
          value: owner_province,
        },
        "personal.user_location.city": {
          value: owner_city,
        },
        "personal.user_information.npwp_number": {
          value: nik,
        },
        "personal.user_information.citizenship_status": {
          value: "indonesian_living_in_indonesia",
        },
        "personal.user_location.home_ownership_status": {
          value: home_ownership_status,
        },
        "personal.user_location.has_lived_for": {
          value: has_lived_for,
        },
        // "personal.user_information.npwp_number": {
        //   value: npwp,
        // },
        "personal.occupation.monthly_income_range": {
          value: monthly_income_range ? monthly_income_range : "",
        },
      },
      emergency_contact: {
        fullname: emergency_contact_name,
        relationship: emergency_contact_relationship
          ? emergency_contact_relationship
          : "",
        phone_area: emergency_contact_phone_number.slice(0, 2),
        phone_number: emergency_contact_phone_number.slice(2),
      },
    };
    actionGetPersonalInfoLocal(paramsSubmit);
    localStorage.setItem("paramsSubmit", JSON.stringify(paramsSubmit));
    localStorage.setItem(
      "paramsSubmitDate",
      JSON.stringify({
        date: owner_birthdate,
        postalCode: isShowCurrentlyAddress
          ? postal_code.toString()
          : postal_code_duplicate.toString(),
      }),
    );
    localStorage.setItem(
      "paramsIsCurrentlyAddress",
      JSON.stringify({ currentlyAddress: isShowCurrentlyAddress }),
    );
    cookie.save("paramsSubmit", paramsSubmit, {
      path: "/",
      maxAge: 3600,
    });
    // putPersonalInfoKoinvoice(
    //   loan_application_id,
    //   paramsSubmit,
    //   setIsShowLoadingSubmitPersonalInfo
    // );
    Object.assign(
      personalInfoProfile,
      kk_image && {
        family_card: {
          value: kk_image,
        },
      },
    );
    putPersonalInfoProfileKoinvoice(
      personalInfoProfile,
      setIsShowLoadingSubmitPersonalInfo,
    );
  }

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (isShowPutBusinessInfoProfile) {
        nextPage();
      }
    }
  });

  function handleChangeBackPersonal() {
    actionButtonBackPersonalInfo();
    prevPage();
  }

  const options =
    district.data &&
    _.uniqBy(district.data, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  const optionsSubDistrict = _.uniqBy(subDistrict, "name").map(
    (data, index) => <Option key={data.name}>{data.name}</Option>,
  );

  let optionsCountry =
    countryOption &&
    _.uniqBy(countryOption, "name").map((data, index) => (
      <Option key={data.name}>{data.name}</Option>
    ));

  const FormItemPersonalInfo = (
    errors,
    handleBlur,
    handleChange,
    values,
    setFieldValue,
  ) => {
    return (
      <React.Fragment>
        <Form.Item
          label={language.form.nik}
          validateStatus={errors.nik && errors.nik && "error"}
          help={errors.nik && errors.nik}
          onBlur={handleBlur}
        >
          <NumberFormat
            style={{
              width: isMobile() ? 500 : "100%",
              height: 40,
              border: "1px solid #d9d9d9",
            }}
            name="nik"
            type="tel"
            placeholder="16***-***3-*34**"
            format="####-####-####-####"
            onChange={(value, option) =>
              handleChangeNik(value, option, setFieldValue)
            }
            value={values.nik}
            id="nik"
          />
        </Form.Item>
        <Form.Item
          label={language.form.user_personal_name}
          validateStatus={errors.owner_name && "error"}
          help={errors.owner_name}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            name="owner_name"
            placeholder="Jhone Doe"
            onChange={handleChange}
            value={values.owner_name}
            id="owner_name"
          />
        </Form.Item>
        <Form.Item
          label={language.form.pob}
          validateStatus={errors.owner_place_of_birth && "error"}
          help={errors.owner_place_of_birth}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            name="owner_place_of_birth"
            placeholder="Semarang"
            onChange={handleChange}
            value={values.owner_place_of_birth}
            id="owner_place_of_birth"
          />
        </Form.Item>
        <Form.Item
          label={language.form.dob}
          validateStatus={errors.owner_birthdate && "error"}
          help={errors.owner_birthdate}
          onBlur={handleBlur}
        >
          <DatePicker
            name="owner_birth_date"
            defaultPickerValue={
              values.owner_birthdate
                ? moment(values.owner_birthdate, "DD-MM-YYYY")
                : moment("01-01-1990", "DD-MM-YYYY")
            }
            value={
              values.owner_birthdate
                ? moment(values.owner_birthdate, "DD-MM-YYYY")
                : ""
            }
            format="DD-MM-YYYY"
            onChange={(value, option) =>
              actionChangeBirthDate(value, option, setFieldValue)
            }
            size="large"
            style={{ width: isMobile() ? 500 : "100%" }}
            id="owner_birth_date"
          />
        </Form.Item>
        <Form.Item
          label={language.form.gender}
          validateStatus={errors.owner_gender && "error"}
          help={errors.owner_gender}
        >
          <Radio.Group
            value={values.owner_gender}
            size="large"
            name="owner_gender"
            onChange={handleChange}
            buttonStyle="solid"
            id="owner_gender"
          >
            <Radio.Button
              name="owner_gender_male"
              id="owner_gender_male"
              value="male"
            >
              {language.label.male}
            </Radio.Button>
            <Radio.Button
              name="owner_gender_female"
              id="owner_gender_female"
              value="female"
            >
              {language.label.female}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={language.form.maritalStatus}
          validateStatus={errors.owner_marital_status && "error"}
          help={errors.owner_marital_status}
        >
          {" "}
          <Select
            showSearch
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.owner_marital_status}
            placeholder="KAWIN"
            onChange={(value, option) =>
              actionChangeMaritalStatus(
                value,
                option,
                setFieldValue,
                businessInformation.data &&
                  (businessInformation.data.details.business.company_detail
                    ?.business_type?.value === "individual" ||
                    businessInformation.data.details.business.company_detail
                      ?.business_type?.value === "home_business"),
              )
            }
            name="owner_marital_status"
            id="owner_marital_status"
          >
            <Option value="BELUM KAWIN">{language.label.singleDivorce}</Option>
            <Option value="KAWIN"> {language.label.married}</Option>
            <Option value="CERAI MATI">{language.label.deathDivorce}</Option>
            <Option value="CERAI HIDUP"> {language.label.divorced}</Option>
          </Select>
        </Form.Item>
        {checkBusinessTypeInformation && (
          <Form.Item
            label={language.form.kartuKeluarga}
            validateStatus={errors.kk_image && "error"}
            help={errors.kk_image}
          >
            {values.kk_image ? (
              <div className="kw-personalInfo__verifiy-ktp">
                <img
                  src={values.kk_image}
                  alt=""
                  className="kw-personalInfo__image-upload"
                />
                <div className="kw-personalInfo__verifiy-ktp__itemOne">
                  <p onClick={handleChangeShowViewKK} id="view_kartu_keluarga">
                    View
                  </p>
                  {pathKK}
                </div>
                <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                  <p>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ marginRight: 5 }}
                    />{" "}
                    Verified
                  </p>
                </div>
              </div>
            ) : (
              <Button
                className="kw-personalInfo__button-upload"
                onClick={handleChangeShowUploadKk}
                style={{
                  border: errors.kk_image
                    ? "2px solid red"
                    : "2px solid #2b486d",
                }}
                size="large"
                id="btn_upload_kartu_keluarga"
              >
                TAKE A PHOTO
              </Button>
            )}
            <p>{language.loanPersonalInfo.textKartuKeluarga}</p>
          </Form.Item>
        )}
        <p>{language.form.idAddress}</p>
        <Form.Item
          label={language.loanFormBusinessInfo.address}
          validateStatus={errors.owner_address && "error"}
          help={errors.owner_address}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            placeholder="Jl...RT01/RW02 dekat toko"
            name={isShowCurrentlyAddress ? "owner-address" : ""}
            onChange={(value, option) =>
              handleChangeAddress(value, option, setFieldValue)
            }
            value={values.owner_address}
            id="owner-address"
          />
        </Form.Item>
        <Form.Item
          label={language.form.subdistrict}
          validateStatus={errors.owner_sub_district && "error"}
          help={errors.owner_sub_district}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            value={values.owner_sub_district}
            name={isShowCurrentlyAddress ? "owner-sub-district" : ""}
            style={{ width: isMobile() ? 500 : "100%" }}
            placeholder="TEBET TIMUR"
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchSubDistrict}
            onChange={(value, option) =>
              actionChangeSubDistrict(value, option, setFieldValue)
            }
            id="owner-sub-district"
          >
            {optionsSubDistrict}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.district}
          validateStatus={errors.owner_district && "error"}
          help={errors.owner_district}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            value={values.owner_district}
            name={isShowCurrentlyAddress ? "owner-district" : ""}
            style={{ width: isMobile() ? 500 : "100%" }}
            //  placeholder="JAKARTA SELATAN"
            size="large"
            defaultActiveFirstOption={false}
            disabled={true}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchDistrict}
            onChange={(value, option) =>
              actionChangeDistrict(value, option, setFieldValue)
            }
            id="owner-district"
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.city}
          validateStatus={errors.owner_city && "error"}
          help={errors.owner_city}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            disabled={true}
            size="large"
            name="owner_city"
            onChange={handleChange}
            value={values.owner_city}
            id="owner_city"
          />
        </Form.Item>
        <Form.Item
          label={language.form.province}
          validateStatus={errors.owner_province && "error"}
          help={errors.owner_province}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            //  placeholder={"DKI JAKARTA"}
            disabled={true}
            size="large"
            name="owner_province"
            onChange={handleChange}
            value={values.owner_province}
            id="owner_province"
          />
        </Form.Item>
        <Form.Item
          label={language.form.postalCode}
          validateStatus={errors.postal_code && "error"}
          help={errors.postal_code}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            placeholder={"30153"}
            type="number"
            size="large"
            name="postal_code"
            onChange={handleChange}
            value={values.postal_code}
            id="postal_code"
          />
        </Form.Item>
        <Form.Item
          label={language.form.ktpOccupation}
          validateStatus={errors.ktp_occupation && "error"}
          help={errors.ktp_occupation}
          onBlur={handleBlur}
        >
          {" "}
          <Select
            showSearch
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.ktp_occupation}
            placeholder="Pelajar/Mahasiswa"
            onChange={(value, option) =>
              handleChangeKtpOccupation(
                value,
                option,
                setFieldValue,
                values.current_occupation,
              )
            }
            name="ktp_occupation"
            id="ktp_occupation"
          >
            <Option value="Pelajar/Mahasiswa">{language.label.student}</Option>
            <Option value="Wiraswasta">{language.label.selfEmployed}</Option>
            <Option value="Pertukangan dan pengrajin (tukang kayu, pengrajin kulit, dan lain-lain)">
              {language.label.carpentryAndCraftsmen}
            </Option>
            <Option value="Ibu Rumah Tangga">{language.label.housewife}</Option>
            <Option value="Pekerja informal (asisten rumah tangga, asongan, dll)">
              {language.label.informalWorkers}
            </Option>
            <Option value="Pejabat negara/penyelenggara negara">
              {language.label.stateOfficials}
            </Option>
            <Option value="Pegawai pemerintahan/lembaga negara (selain pejabat/penyelenggara negara)">
              {language.label.governmentOfficials}
            </Option>
            <Option value="Lain-lain">{language.label.otherOccupation}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.citizenship}
          validateStatus={errors.citizenship && "error"}
          help={errors.citizenship}
        >
          <Select
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.citizenship}
            placeholder="WNI"
            onChange={(value, option) =>
              actionChangeCitizenship(value, option, setFieldValue, values)
            }
            id="citizenship"
          >
            <Option value="WNI">WNI</Option>
            <Option value="WNA">WNA</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.country}
          validateStatus={errors.country && "error"}
          help={errors.country}
          onBlur={handleBlur}
        >
          <Select
            showSearch
            disabled={isCountryDisabled}
            value={values.country}
            name="country"
            placeholder="Indonesia"
            style={{ width: isMobile() ? 500 : "100%" }}
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={actionChangeSearchCountry}
            onChange={(value, option) =>
              actionChangeCountry(value, option, setFieldValue)
            }
            id="country"
          >
            {optionsCountry}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.education}
          validateStatus={errors.education_level && "error"}
          help={errors.education_level}
        >
          <Select
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.education_level}
            placeholder="SD / Elementary School"
            onChange={(value, option) =>
              actionChangeEducation_level(value, option, setFieldValue, values)
            }
            id="education-level"
          >
            <Option value="SD">SD / Elementary School</Option>
            <Option value="SMP">SMP / Junior High School</Option>
            <Option value="SMA">SMA / High School</Option>
            <Option value="DIPLOMA DEGREE">Diploma Degree (D1/D2/D3)</Option>
            <Option value="S1">S1 / Bachelor Degree</Option>
            <Option value="S2">S2 / Master Degree</Option>
            <Option value="S3">S3 / Doctorate Degree</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.motherMaidenName}
          validateStatus={errors.mother_maiden_name && "error"}
          help={errors.mother_maiden_name}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            name="mother_maiden_name"
            onChange={handleChange}
            value={values.mother_maiden_name}
            id="mother_maiden_name"
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isShowCurrentlyAddress}
            onChange={onChangeAddressCurrentlyLive}
            name="is_show_current_address"
            id="is_show_current_address"
          >
            {language.loanPersonalInfo.checklistCurrently}
          </Checkbox>
        </Form.Item>
        {isShowCurrentlyAddress === false && (
          <React.Fragment>
            <p>{language.loanPersonalInfo.currentHomeAddress}</p>
            <Form.Item>
              <Radio.Group
                onChange={onChangeCurrentHomeAddress}
                value={homeAddress}
                id="business-address"
              >
                <Radio
                  value="same-with-business-address"
                  id="same-with-business-address"
                >
                  {language.loanPersonalInfo.sameWithAddress}
                </Radio>
                <Radio
                  value="different-address"
                  name="address_different"
                  id="address_different"
                >
                  {language.loanPersonalInfo.differentAddress}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={language.loanFormBusinessInfo.address}
              validateStatus={errors.owner_address_duplicate && "error"}
              help={errors.owner_address_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                type="text"
                size="large"
                name="owner_address_duplicate"
                placeholder="Jl...RT01/RW02 dekat toko"
                onChange={(value, option) =>
                  handleChangeAddressDuplicate(value, option, setFieldValue)
                }
                value={values.owner_address_duplicate}
                id="owner_address_duplicate"
              />
            </Form.Item>
            <Form.Item
              label={language.form.subdistrict}
              validateStatus={errors.owner_sub_district_duplicate && "error"}
              help={errors.owner_sub_district_duplicate}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.owner_sub_district_duplicate}
                name="owner_sub_district_duplicate"
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder="TEBET TIMUR"
                size="large"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchSubDistrict}
                onChange={(value, option) =>
                  actionChangeSubDistrictDuplicate(value, option, setFieldValue)
                }
                id="owner_sub_district_duplicate"
              >
                {optionsSubDistrict}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.district}
              validateStatus={errors.owner_district_duplicate && "error"}
              help={errors.owner_district_duplicate}
              onBlur={handleBlur}
            >
              <Select
                showSearch
                value={values.owner_district_duplicate}
                name="owner_district_duplicate"
                style={{ width: isMobile() ? 500 : "100%" }}
                size="large"
                defaultActiveFirstOption={false}
                placeholder="JAKARTA SELATAN"
                showArrow={false}
                filterOption={false}
                onSearch={actionChangeSearchDistrict}
                onChange={(value, option) =>
                  actionChangeDistrictDuplicate(value, option, setFieldValue)
                }
                id="owner_district_duplicate"
              >
                {options}
              </Select>
            </Form.Item>
            <Form.Item
              label={language.form.province}
              validateStatus={errors.owner_province_duplicate && "error"}
              help={errors.owner_province_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"DKI JAKARTA"}
                disabled={true}
                size="large"
                name="owner_province_duplicate"
                onChange={handleChange}
                value={values.owner_province_duplicate}
                id="owner_province_duplicate"
              />
            </Form.Item>
            <Form.Item
              label={language.form.postalCode}
              validateStatus={errors.postal_code_duplicate && "error"}
              help={errors.postal_code_duplicate}
              onBlur={handleBlur}
            >
              <Input
                style={{ width: isMobile() ? 500 : "100%" }}
                placeholder={"30153"}
                type="number"
                size="large"
                name="postal_code_duplicate"
                onChange={handleChange}
                value={values.postal_code_duplicate}
                id="postal_code_duplicate"
              />
            </Form.Item>
            <Form.Item
              label={language.form.currentOccupation}
              validateStatus={errors.current_occupation && "error"}
              help={errors.current_occupation}
              onBlur={handleBlur}
            >
              {" "}
              <Select
                showSearch
                size={"large"}
                style={{ width: isMobile() ? 500 : "100%" }}
                value={values.current_occupation}
                placeholder="Pelajar/Mahasiswa"
                onChange={(value, option) =>
                  handleChangeCurrentOccupation(value, option, setFieldValue)
                }
                name="current_occupation"
                id="current_occupation"
              >
                <Option value="Pelajar/Mahasiswa">
                  {language.label.student}
                </Option>
                <Option value="Wiraswasta">
                  {language.label.selfEmployed}
                </Option>
                <Option value="Pertukangan dan pengrajin (tukang kayu, pengrajin kulit, dan lain-lain)">
                  {language.label.carpentryAndCraftsmen}
                </Option>
                <Option value="Ibu Rumah Tangga">
                  {language.label.housewife}
                </Option>
                <Option value="Pekerja informal (asisten rumah tangga, asongan, dll)">
                  {language.label.informalWorkers}
                </Option>
                <Option value="Pejabat negara/penyelenggara negara">
                  {language.label.stateOfficials}
                </Option>
                <Option value="Pegawai pemerintahan/lembaga negara (selain pejabat/penyelenggara negara)">
                  {language.label.governmentOfficials}
                </Option>
                <Option value="Lain-lain">
                  {language.label.otherOccupation}
                </Option>
              </Select>
            </Form.Item>
          </React.Fragment>
        )}
        <Form.Item
          label={language.form.monthlyIncomeRange}
          validateStatus={errors.monthly_income_range && "error"}
          help={errors.monthly_income_range}
          onBlur={handleBlur}
        >
          {" "}
          <Select
            showSearch
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.monthly_income_range}
            placeholder="<Rp 2.000.001"
            onChange={(value, option) =>
              handleChangeMonthlyIncomeRange(value, option, setFieldValue)
            }
            name="monthly_income_range"
            id="monthly_income_range"
            allowClear={!businessType}
          >
            <Option value="<Rp 2.000.001">{"<Rp 2.000.001"}</Option>
            <Option value="Rp. 2.000.001 - Rp 5.000.000">
              Rp 2.000.001 - Rp 5.000.000
            </Option>
            <Option value="Rp 5.000.001 - Rp 7.500.000">
              Rp 5.000.001 - Rp 7.500.000
            </Option>
            <Option value="Rp 7.500.001 - Rp 10.000.000">
              Rp 7.500.001 - Rp 10.000.000
            </Option>
            <Option value="Rp 10.000.001 - Rp 15.000.000">
              Rp 10.000.001 - Rp 15.000.000
            </Option>
            <Option value="Rp 15.000.001 - Rp 20.000.000">
              Rp 15.000.001 - Rp 20.000.000
            </Option>
            <Option value="Rp 20.000.001 - Rp 50.000.000">
              Rp 20.000.001 - Rp 50.000.000
            </Option>
            <Option value="> Rp 50.000.000">{"> Rp 50.000.000"}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.emergencyContactName}
          validateStatus={errors.emergency_contact_name && "error"}
          help={errors.emergency_contact_name}
          onBlur={handleBlur}
        >
          <Input
            style={{ width: isMobile() ? 500 : "100%" }}
            type="text"
            size="large"
            name="emergency_contact_name"
            placeholder="Jhone Doe"
            onChange={handleChange}
            value={values.emergency_contact_name}
            id="emergency_contact_name"
          />
        </Form.Item>
        <Form.Item
          label={language.form.emergencyContactRelationship}
          onBlur={handleBlur}
        >
          {" "}
          <Select
            showSearch
            allowClear
            size={"large"}
            style={{ width: isMobile() ? 500 : "100%" }}
            value={values.emergency_contact_relationship}
            placeholder="Sibling"
            onChange={(value, option) =>
              handleChangeEmergencyContactRelationship(
                value,
                option,
                setFieldValue,
              )
            }
            name="emergency_contact_relationship"
            id="emergency_contact_relationship"
          >
            <Option value="sibling">{language.label.sibling}</Option>
            <Option value="parent">{language.label.parent}</Option>
            <Option value="spouse">{language.label.spouse}</Option>
            <Option value="uncle_or_auntie">{language.label.uncle}</Option>
            <Option value="guardian">{language.label.guardian}</Option>
            <Option value="child">{language.label.child}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.form.emergencyContactPhoneNumber}
          validateStatus={
            errors.emergency_contact_phone_number &&
            errors.emergency_contact_phone_number &&
            errors.emergency_contact_phone_number &&
            "error"
          }
          help={
            errors.emergency_contact_phone_number &&
            errors.emergency_contact_phone_number &&
            errors.emergency_contact_phone_number
          }
          onBlur={handleBlur}
        >
          <PhoneInput
            value={values.emergency_contact_phone_number}
            inputStyle={{ width: isMobile() ? 500 : "100%" }}
            name="emergency_contact_phone_number"
            prefix={""}
            country={"id"}
            onChange={(value, option) =>
              handleChangeEmergencyContactPhoneNumber(
                value,
                option,
                setFieldValue,
              )
            }
            inputProps={{ id: "emergency_contact_phone_number" }}
          />
        </Form.Item>
        <Form.Item
          label={language.loanShareHolder.haslivedLabel}
          validateStatus={errors.has_lived_for && "error"}
          help={errors.has_lived_for}
          onBlur={handleBlur}
        >
          {" "}
          <Select
            value={values.has_lived_for}
            style={{ width: isMobile() ? 500 : "100%" }}
            size="large"
            name="has_lived_for"
            placeholder="1-3 Years"
            onChange={(value, option) =>
              actionChangeLivedFor(value, option, setFieldValue)
            }
            id="has_lived_for"
          >
            <Option key={"< 1 th"}>
              {language.loanShareHolder.livedFor1Years}
            </Option>
            <Option key={"1-3 th"}>
              {language.loanShareHolder.livedFor13Years}
            </Option>
            <Option key={"3-5 th"}>
              {language.loanShareHolder.livedFor35Years}
            </Option>
            <Option key={"> 5 th"}>
              {language.loanShareHolder.livedFor5Years}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={language.loanShareHolder.homeStatusOwnerShipNonLMS}
          validateStatus={errors.home_ownership_status && "error"}
          help={errors.home_ownership_status}
          onBlur={handleBlur}
        >
          <Select
            size={"large"}
            value={values.home_ownership_status}
            onChange={(value, option) =>
              handleChangeOwnerShip(value, option, setFieldValue)
            }
            placeholder={language.loanShareHolder.homeStatusOwner}
            style={{ width: isMobile() ? 500 : "100%" }}
            id="home_ownership_status"
            name="home_ownership_status"
          >
            <Option key={"milik_sendiri"}>
              {language.loanShareHolder.homeStatusOwner}
            </Option>
            <Option key={"milik_orang_tua"}>
              {language.loanShareHolder.homeStatusParents}
            </Option>
            <Option key={"kos"}>
              {language.loanShareHolder.homeStatusRent}
            </Option>
            <Option key={"sewa"}>
              {language.loanShareHolder.homeStatusBoardingHouse}
            </Option>
          </Select>
        </Form.Item>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="kw-personalInfo">
        <Button
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 15,
            marginTop: 15,
          }}
          onClick={() => handleChangeBackPersonal()}
          id="btn-back-personal"
        >
          <ArrowLeftOutlined /> {language.button.back}
        </Button>
        <h3>{language.loanPersonalInfo.headingPersonalInfo}</h3>
        <Formik
          initialValues={{
            owner_email:
              businessInformation.data && businessInformation.data.email,
            owner_phone:
              businessInformation.data &&
              `${businessInformation.data.phone_area}${businessInformation.data.phone_number}`,
            ktp_image:
              dataPersonalInformationLocal.ktp_image || paramsSubmit.ktp_image,
            ktp_selfie_image:
              dataPersonalInformationLocal.ktp_selfie_image ||
              paramsSubmit.ktp_selfie_image,
            kk_image:
              dataPersonalInformationLocal.kk_image || paramsSubmit.kk_image,
            nik: dataPersonalInformationLocal.nik || paramsSubmit.nik,
            owner_address:
              dataPersonalInformationLocal.owner_address ||
              paramsSubmit.owner_address,
            owner_birthdate: ownerInfoDate.date,
            owner_district:
              dataPersonalInformationLocal.owner_district ||
              paramsSubmit.owner_district,
            owner_gender:
              dataPersonalInformationLocal.owner_gender ||
              paramsSubmit.owner_gender,
            owner_marital_status:
              dataPersonalInformationLocal.owner_marital_status ||
              paramsSubmit.owner_marital_status,
            owner_place_of_birth:
              dataPersonalInformationLocal.owner_place_of_birth ||
              paramsSubmit.owner_place_of_birth,
            owner_name:
              dataPersonalInformationLocal.owner_name ||
              paramsSubmit.owner_name,
            owner_sub_district:
              dataPersonalInformationLocal.owner_sub_district ||
              paramsSubmit.owner_sub_district,
            owner_province:
              dataPersonalInformationLocal.owner_province ||
              paramsSubmit.owner_province,
            owner_city:
              dataPersonalInformationLocal.owner_city ||
              paramsSubmit.owner_city,
            owner_province_duplicate:
              dataPersonalInformationLocal.owner_province_duplicate ||
              paramsSubmit.owner_province_duplicate,
            postal_code:
              dataPersonalInformationLocal.postal_code ||
              paramsSubmit.postal_code,
            has_lived_for:
              dataPersonalInformationLocal.has_lived_for ||
              paramsSubmit.has_lived_for,
            home_ownership_status:
              dataPersonalInformationLocal.home_ownership_status ||
              paramsSubmit.home_ownership_status,
            ktp_occupation:
              dataPersonalInformationLocal.ktp_occupation ||
              paramsSubmit.ktp_occupation,
            citizenship:
              dataPersonalInformationLocal.citizenship ||
              paramsSubmit.citizenship,
            country:
              dataPersonalInformationLocal.country || paramsSubmit.country,
            education_level:
              dataPersonalInformationLocal.education_level ||
              paramsSubmit.education_level,
            mother_maiden_name:
              dataPersonalInformationLocal.mother_maiden_name ||
              paramsSubmit.mother_maiden_name,
            current_occupation:
              dataPersonalInformationLocal.current_occupation ||
              paramsSubmit.current_occupation ||
              dataPersonalInformationLocal.ktp_occupation ||
              paramsSubmit.ktp_occupation,
            monthly_income_range:
              dataPersonalInformationLocal.monthly_income_range ||
              paramsSubmit.monthly_income_range,
            emergency_contact_name:
              dataPersonalInformationLocal.emergency_contact_name ||
              paramsSubmit.emergency_contact_name,
            emergency_contact_relationship:
              dataPersonalInformationLocal.emergency_contact_relationship ||
              paramsSubmit.emergency_contact_relationship,
            emergency_contact_phone_number:
              dataPersonalInformationLocal.emergency_contact_phone_number ||
              paramsSubmit.emergency_contact_phone_number,
          }}
          validationSchema={schemaPersonalInfo}
          validateOnChange={false}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Item
                label={language.loanShareHolder.ktp}
                validateStatus={
                  touched.ktp_image && errors.ktp_image && "error"
                }
                help={touched.ktp_image && errors.ktp_image}
              >
                {validationImageKtp === 3 ||
                upload.data ||
                dataPersonalInformationLocal.ktp_image ||
                paramsSubmit.ktp_image ? (
                  <div className="kw-personalInfo__verifiy-ktp">
                    <img
                      src={values.ktp_image}
                      alt=""
                      className="kw-personalInfo__image-upload"
                    />
                    <div className="kw-personalInfo__verifiy-ktp__itemOne">
                      <p onClick={handleChangeShowViewKtp} id="view_ktp">
                        View
                      </p>
                      {pathKtp}
                    </div>
                    {upload.data && uploadKtpSucess.data ? (
                      <React.Fragment>
                        {isShowHideAlertUploadKtp && isShowHideAlertKtp && (
                          <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                            <p>
                              <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                style={{ marginRight: 5 }}
                              />{" "}
                              Verified
                            </p>
                          </div>
                        )}
                        <div className="kw-personalInfo__verifiy-ktp__itemOne">
                          <p
                            onClick={() =>
                              handleChangeShowUploadKtp("reUploadKtp")
                            }
                            id="reupload_ktp"
                          >
                            Reupload
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      upload.data &&
                      uploadKtpError && (
                        <div className="kw-personalInfo__verifiy-ktp__itemOne">
                          <p
                            onClick={() =>
                              handleChangeShowUploadKtp("reUploadKtp")
                            }
                            id="reupload_ktp"
                          >
                            Reupload
                          </p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <Button
                    className="kw-personalInfo__button-upload"
                    onClick={handleChangeShowUploadKtp}
                    style={{
                      border: errors.ktp_image
                        ? "2px solid red"
                        : "2px solid #2b486d",
                    }}
                    size="large"
                    id="btn_upload_ktp"
                  >
                    UPLOAD PHOTO KTP
                  </Button>
                )}
              </Form.Item>
              <Form.Item
                label={language.loanShareHolder.selfieKtp}
                validateStatus={
                  touched.ktp_selfie_image && errors.ktp_selfie_image && "error"
                }
                help={touched.ktp_selfie_image && errors.ktp_selfie_image}
              >
                {validationMatchImageKtp === 3 ||
                uploadSelfie.data ||
                dataPersonalInformationLocal.ktp_selfie_image ||
                paramsSubmit.ktp_selfie_image ? (
                  <div className="kw-personalInfo__verifiy-ktp">
                    <img
                      src={values.ktp_selfie_image}
                      alt=""
                      className="kw-personalInfo__image-upload"
                    />
                    <div className="kw-personalInfo__verifiy-ktp__itemOne">
                      <p
                        onClick={handleChangeShowViewMatchKtp}
                        id="view_selfie_ktp"
                      >
                        View
                      </p>
                      {pathSelfieKtp}
                    </div>
                    {uploadSelfie.data && uploadMatchKtpSucess.data ? (
                      <React.Fragment>
                        {isShowHideAlertMatchKtp &&
                          isShowHideAlertUploadMatchKtp && (
                            <div className="kw-personalInfo__verifiy-ktp__itemTwo">
                              <p>
                                <CheckCircleTwoTone
                                  twoToneColor="#52c41a"
                                  style={{ marginRight: 5 }}
                                />{" "}
                                Verified
                              </p>
                            </div>
                          )}
                        <div className="kw-personalInfo__verifiy-ktp__itemOne">
                          <p
                            onClick={() =>
                              handleChangeShowUploadSelfieKtp("reUploadKtp")
                            }
                            id="reupload_selfie_ktp"
                          >
                            Reupload
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      uploadSelfie.data &&
                      uploadMatchKtpError && (
                        <div className="kw-personalInfo__verifiy-ktp__itemOne">
                          <p
                            onClick={() =>
                              handleChangeShowUploadSelfieKtp("reUploadKtp")
                            }
                            id="reupload_selfie_ktp"
                          >
                            Reupload
                          </p>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <Tooltip
                    title={upload.data ? "" : "Upload Selfie + KTP Terdahulu"}
                    color={"orange"}
                    key={"orange"}
                  >
                    <Button
                      className="kw-personalInfo__button-upload"
                      onClick={handleChangeShowUploadSelfieKtp}
                      disabled={upload.data ? false : true}
                      style={{
                        border: errors.ktp_selfie_image
                          ? "1px solid red"
                          : "2px solid #2b486d",
                      }}
                      size="large"
                      id="btn_upload_selfie_ktp"
                    >
                      UPLOAD SELFIE+KTP
                    </Button>
                  </Tooltip>
                )}
              </Form.Item>
              {FormItemPersonalInfo(
                errors,
                handleBlur,
                handleChange,
                values,
                setFieldValue,
              )}
              <p style={{ marginTop: 20, marginBottom: "0rem" }}>
                {language.loanPersonalInfo.contact}
              </p>
              <Form.Item
                label={language.form.mobileNumber}
                validateStatus={
                  touched.owner_phone && errors.owner_phone && "error"
                }
                help={touched.owner_phone && errors.owner_phone}
              >
                <PhoneInput
                  value={values.owner_phone}
                  inputStyle={{ width: 500 }}
                  name="owner_phone"
                  prefix={""}
                  country={"id"}
                  onChange={(value, option) =>
                    handleChangePhoneInput(value, option, setFieldValue)
                  }
                  inputProps={{ id: "owner_phone" }}
                />
              </Form.Item>
              <Form.Item
                label={language.form.businessEmail}
                validateStatus={
                  touched.owner_email && errors.owner_email && "error"
                }
                help={touched.owner_email && errors.owner_email}
                onBlur={handleBlur}
              >
                <Input
                  style={{ width: isMobile() ? 500 : "100%" }}
                  type="email"
                  placeholder="john@doe.com"
                  size="large"
                  name="owner_email"
                  onChange={handleChange}
                  value={values.owner_email}
                  id="owner_email"
                />
              </Form.Item>
              {putPersonalInfoProfileError.data && (
                <Alert
                  type="error"
                  style={{
                    marginBottom: 25,
                    width: isMobile() ? "50%" : "100%",
                  }}
                  message={`${putPersonalInfoProfileError.data.message.en} / ${putPersonalInfoProfileError.data.message.id}`}
                />
              )}
              <Spin
                spinning={isShowLoadingSubmitPeronalInfo}
                style={{
                  width: isMobile() ? "70%" : "100%",
                }}
              >
                <Button
                  htmlType="submit"
                  className="kw-button"
                  size="large"
                  type="primary"
                  id="btn-next-personal"
                >
                  {language.button.next}
                </Button>
              </Spin>
              <ModalViewKtp
                visible={isShowViewKtpModal}
                imageModalView={
                  (upload.data && upload.data[0]) ||
                  dataPersonalInformationLocal.ktp_image
                }
                handleCancel={handleChangeShowViewKtp}
              />
              <ModalViewMatchKtp
                visible={isShowViewMatchKtpModal}
                imageModalView={
                  (uploadSelfie.data && uploadSelfie.data[0]) ||
                  dataPersonalInformationLocal.selfie_ktp
                }
                handleCancel={handleChangeShowViewMatchKtp}
              />
              <ModalViewKK
                visible={isShowViewKKModal}
                imageModalView={
                  (uploadKK.data && uploadKK.data[0]) ||
                  dataPersonalInformationLocal.kk_image
                }
                handleCancel={handleChangeShowViewKK}
              />
              <UploadKtpModal
                visible={visibleUploadKtp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                uploadKtpError={uploadKtpError}
                validationImageKtp={
                  isShowvalidationImageKtpPersonal
                    ? validationImageKtpPersonal
                    : validationImageKtp
                }
                handleChangeShowUploadKtp={handleChangeShowUploadKtp}
                actionChangeKtpUpload={actionChangeKtpUpload}
              />
              <UploadSelfieKtpModal
                visible={visibleUploadSelfieKtp}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                businessInformation={businessInformation}
                uploadKtpError={uploadMatchKtpError}
                setSelfieKtp={setSelfieKtp}
                validationMatchImageKtp={
                  isShowvalidationMatchImageKtpFailed
                    ? validationMatchImageKtpFailed
                    : validationMatchImageKtp
                }
                setValidationMatchImageKtp={setValidationMatchImageKtp}
                validationMatchImageKtpFailed={validationMatchImageKtpFailed}
                setIsShowAlertMatchKtp={setIsShowAlertMatchKtp}
                setIsShowAlertUploadMatchKtp={setIsShowAlertUploadMatchKtp}
                setValidationMatchImageKtpFailed={
                  setValidationMatchImageKtpFailed
                }
                handleChangeShowUploadSelfieKtp={
                  handleChangeShowUploadSelfieKtp
                }
              />
              <UploadKkModal
                visible={visibleUploadKk}
                isLoading={isShowLoading}
                setFieldValue={setFieldValue}
                businessInformation={businessInformation}
                setPathKK={setPathKK}
                validationImageKk={validationImageKk}
                setValidationImageKk={setValidationImageKk}
                handleChangeShowUploadKk={handleChangeShowUploadKk}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  init: state.init,
  koinvoice: state.koinvoice,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadBusinessKoinVoice,
      putPersonalInfoKoinvoice,
      putPersonalInfoProfileKoinvoice,
      actionPutAppGuarantorCreate,
      businessInformationKoinvoice,
      actionButtonBackPersonalInfo,
      getDistrictKoinvoice,
      getSubDistrictKoinvoice,
      actionGetPersonalInfoLocal,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Personalnfo);
