import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeLanguage } from "../../../stores/actions/account";
import { Link } from "react-router-dom";
import { NavMainWrapper } from "./NavMain.styles";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

import koinLogo from "../../../assets/images/koinworksLogo.png";
// import koinLogoKoinInvoice from "../../../assets/images/icon-koininvoice.png";
// import koinLogoKoinInvoice from "../../../assets/images/KoinInvoice-logo-icon.png";
import { hostNameCheckCondition } from "../../../library/checkHostNameWeb";

class NavMain extends Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage = ({ target }) => {
    localStorage.setItem("language", target.value);
    this.props.changeLanguage(target.value);
  };

  render() {
    let {
      init: { language, flag },
    } = this.props;
    return (
      <NavMainWrapper>
        {/* navbar */}
        <section className="main-nav fixed-top">
          <div className="container">
            <Navbar expand="lg">
              <Navbar.Brand href="#home">
                <figure className="navlogo">
                  <img
                    src={
                      hostNameCheckCondition ? "https://koinworks-assets.oss-ap-southeast-5.aliyuncs.com/public/koinp2p_logo.png" : koinLogo
                    }
                    alt=""
                  />
                </figure>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link
                    target="_blank"
                    href="https://koinworks.com/super-app/"
                  >
                    {language.homepage.navBeranda}
                  </Nav.Link>
                  {hostNameCheckCondition ? (
                    ""
                  ) : (
                    <NavDropdown
                      title={language.homepage.invest}
                      id="dropdown-invest"
                    >
                      <NavDropdown.Item href="https://koinworks.com/super-app/koinp2p/">
                        KoinP2P
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://koinworks.com/super-app/koinrobo/">
                        KoinRobo
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://koinworks.com/super-app/diversifikasi/">
                        {language.homepage.diversification}
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                  {hostNameCheckCondition ? (
                    ""
                  ) : (
                    <NavDropdown
                      title={language.homepage.borrow}
                      id="dropdown-borrow"
                    >
                      <NavDropdown.Item href="https://koinworks.com/super-app/koinbisnis/">
                        KoinBisnis
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://koinworks.com/id/pinjaman/dana-pendidikan">
                        KoinPintar
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/login">
                        KoinEmployee
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                  {hostNameCheckCondition ? (
                    <Nav.Link href="#typeproduct">
                      {language.homepage.navProduct}
                    </Nav.Link>
                  ) : (
                    <Nav.Link href="https://koinworks.com/super-app/fitur/">
                      {language.homepage.features}
                    </Nav.Link>
                  )}
                  {hostNameCheckCondition ? (
                    <Nav.Link href="#how-it-play">
                      {language.homepage.navRequirements}
                    </Nav.Link>
                  ) : (
                    <Nav.Link href="https://koinworks.com/blog/">
                      {language.homepage.blog}
                    </Nav.Link>
                  )}
                  {hostNameCheckCondition && (
                    <Nav.Link href="#solution">
                      {language.homepage.navHowItWorks}
                    </Nav.Link>
                  )}
                  <NavDropdown
                    title={language.homepage.company}
                    id="dropdown-company"
                  >
                    <NavDropdown.Item href="https://koinworks.com/super-app/tentang-kami/">
                      {language.homepage.aboutUs}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://koinworks.com/super-app/en/career/">
                      {language.homepage.career}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://koinworks.com/super-app/hubungi-kami/">
                      {language.homepage.contactUs}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://koinworks.com/super-app/faq/">
                      {language.homepage.faq}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>

                {hostNameCheckCondition ? (
                  <Link to="/login">
                    <button
                      className="btn btn-primary btn-app"
                      style={{ background: "#378DF4", borderColor: "unset" }}
                    >
                      LOGIN
                    </button>
                  </Link>
                ) : (
                  <Link to="/login">
                    <button className="btn btn-primary btn-app">
                      GO TO APP
                    </button>
                  </Link>
                )}

                <div
                  className="lang-selector ml-3"
                  style={{ alignSelf: "center" }}
                  onChange={this.changeLanguage}
                >
                  <input
                    id="lang-id"
                    className="lang"
                    type="radio"
                    value="id"
                    name="language"
                    defaultChecked={flag === "id"}
                  />
                  <label htmlFor="lang-id">ID</label>
                  <input
                    id="lang-en"
                    className="lang"
                    type="radio"
                    value="en"
                    name="language"
                    defaultChecked={flag === "en"}
                  />
                  <label htmlFor="lang-en">EN</label>
                </div>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </section>
      </NavMainWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeLanguage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMain);
